* {
  padding: 0;
  margin: 0;
}

.img-rounded {
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.75);
}
